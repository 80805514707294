@font-face {
  font-family: Aoboshi;
  src: url("./fonts/AoboshiOne-Regular.ttf");
}

* {
  font-family: Aoboshi;
}

html,
body {
  font-family: Aoboshi !important;
  background-color: rgb(223, 223, 223) !important;
}

@media screen and (min-width: 1440px) {
  body {
    margin: 0 auto;
    max-width: 1400px;
  }
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free" !important;
}

.fab {
  font-family: "Font Awesome 5 Brands" !important;
}

.navbar {
  color: white;
}

.appear {
  animation: appear 1.5s ease-in;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.top-bar {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  padding: 0 20px;
}

.title {
  text-align: center;
}

.myName {
  font-family: "Cormorant", serif !important;
  color: #303030;
  text-shadow: rgb(167, 167, 167) -2px 2px 2px;
  font-size: 44px;
  margin-top: 0.5em;
}

.my-title {
  font-style: italic;
  font-family: "Cormorant", serif !important;
  color: #303030;
}

.links {
  float: right;
}

.topbar-center-logo {
  background: #ffffff;
}

.topbar-center-logo .menu {
  background: transparent;
}

.topbar-center-logo .menu a {
  color: #ffffff !important;
  font-family: "Cormorant", serif !important;
  font-size: 18px;
}

.project-description {
  color: #303030;
  text-align: center;
  margin-top: 15px;
  opacity: 0;
}

.single-project:hover .project-description {
  transition: opacity 1s, visibility 0.2s;
  opacity: 1;
}

.project-view {
  margin-top: 5%;
}

.projImage {
  max-width: 150px;
  max-height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40%;
}

.topbar-center-logo .top-bar-center {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.my-projects {
  font-family: "Fira Sans Condensed", sans-serif;
  text-align: center;
  letter-spacing: -2px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: rgb(255, 255, 255);
  outline: none;
  text-align: center;
}

.my-projects:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 3.5px;
  text-shadow: black -1px 1px 2px;
}

.social-footer {
  font-family: "Cormorant", serif !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  background: transparent;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#footer-icon {
  font-size: 32px;
  color: rgb(240, 240, 240);
  text-shadow: -1px 1px 4px #838383;
  margin-right: 0.5em;
}

.social-footer p {
  padding-top: 25px;
  color: white;
  font-size: 14px;
}

.social-footer .social-footer-icons li:last-of-type {
  margin-right: 0;
}

.social-footer .social-footer-icons .fa {
  font-size: 1.3rem;
  color: #ffffff;
}

.social-footer .social-footer-icons .fa:hover {
  color: #4a4a4a;
  transition: color 0.3s ease-in;
}

.footerLogo {
  color: white;
  list-style: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.cont-space {
  padding-top: 5%;
}

@media screen and (max-width: 700px) {
  .options {
    display: block !important;
    justify-content: center;
    width: fit-content;
    margin: auto;
  }
  .menu.simple a {
    margin-right: 0em !important;
  }
  #footer-icon {
    margin-right: 0.35em !important;
  }
}

.options {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .selection {
    max-width: 100px;
    font-size: 12px !important;
  }
}

.selection {
  width: 11em;
  height: 3.5em;
  font-family: "Cormorant", serif !important;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.selection::before {
  content: "";
  width: 0;
  height: 3.5em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0f9fd8 0%, #3096c5 100%);
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.selection:hover::before {
  width: 11em;
  color: white;
}

.selection:hover {
  color: white;
}

.viewMore {
  padding: 20px;
  justify-content: center;
}

.enterProject:active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
}

.card {
  margin: auto;
  width: 190px;
  height: 254px;
  border-radius: 30px;
  background: rgb(19, 19, 19);
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.lang {
  font-size: 13px;
  padding-top: 45px;
  text-align: center;
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
}

.projectDisplay {
  display: flex;
}

.intro-container {
  height: 250px !important;
}

#intro {
  color: var(--color-grey);
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
}

.navCard {
  position: relative;
  margin: auto;
  padding-bottom: 120px;
}

#prev {
  color: #353535 !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

#nxt {
  color: #353535 !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

#contact-info .links {
  display: flex;
  min-width: 150px;
  justify-content: space-between;
}

#contact-info a {
  text-decoration: none;
  color: var(--color-grey);
  font-size: 2rem;
}

#contact-info .links a {
  top: 0;
  display: block;
  position: relative;
}
#contact-info .links a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  transition: all 0.3s;
}
#contact-info .links a:hover::after {
  --transformed-box-diagonal-length: 164%;
  width: var(--transformed-box-diagonal-length);
  left: calc((100% - var(--transformed-box-diagonal-length)) / 2);
}

#contact-info .links a div {
  position: relative;
  display: block;
  width: 55px;
  height: 55px;
  transition: transform 0.3s;
}

#contact-info .links a:hover div {
  transform: rotate(-35deg) skew(20deg);
}

.links a span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  border-radius: 5px;
}

.links a:hover span:nth-child(5) {
  transform: translate(20px, -20px);
  opacity: 1;
}

@media screen and (max-width: 700px) {
  .contact-card {
    max-width: 90% !important;
    height: 20em !important;
    margin-top: 20% !important;
  }
  .contact {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 701px) and (max-width: 1024px) {
  .contact-card {
    max-width: 70% !important;
    margin-top: 20% !important;
  }
}

.contact-card {
  font-family: "Cormorant", serif !important;
  background-image: url("http://www.textures4photoshop.com/tex/thumbs/seamless-paper-texture-business-card-background-thumb47.jpg");
  text-align: center;
  max-width: 50%;
  height: 25rem;
  margin: auto;
  border-radius: 10px;
  box-shadow: 6px 6px 12px #a3a3a3, -6px -6px 12px #ffffff;
  animation: slide-in 1.5s forwards;
  margin-top: 10%;
  transform: translateX(-100%);
}

@keyframes slide-in {
  to {
    transform: translateX(0%);
  }
}

.contact {
  padding-top: 100px;
  color: #303030 !important;
  font-family: "Cormorant", serif !important;
  text-shadow: -1px 2px 3px #888787, 1px -1px 2px #ffffff !important;
}

.social-contact {
  list-style: none;
  display: block;
}

.social-link {
  color: #303030 !important;
  text-decoration: none;
  text-align: center;
}

i {
  padding-right: 10px;
}

a {
  font-family: "Cormorant", serif;
  color: #303030 !important;
}

.moreContact {
  display: block;
  list-style: none;
}

.reachOut {
  font-family: "Cormorant", serif !important;
  padding-top: 80px;
  font-style: italic;
}

.return-home {
  margin: 0;
  position: absolute;
  left: 45%;
  transform: translate(-0%, -400%);
  animation: phasein 3s;
}

.back {
  text-align: center;
}

.deployed-footer {
  width: 100%;
  bottom: 0;
  margin: auto;
  display: block;
  position: absolute;
}

.project-links {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgb(212, 212, 212);
}

.project-links > a {
  color: #303030 !important;
  font-family: Aoboshi !important;
  font-size: 14px;
}

@keyframes phasein {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.portrait,
.about-me {
  display: inline-block;
}

.portrait {
  vertical-align: top;
}

.about-me {
  width: 40%;
}

.tech-used {
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .icons i {
    font-size: 30px !important;
  }
}

@media (max-width: 900px) {
  .computer-display,
  .about-me {
    display: block;
    position: inherit !important;
    width: 100%;
  }
  .computer-display {
    right: 0% !important;
    max-width: 300px !important;
    max-height: 300px !important;
    margin: auto;
  }
  .about-proj {
    display: block;
    position: inherit !important;
    width: 90% !important;
    margin: auto !important;
    text-align: center !important;
    margin-top: 3em !important;
  }

  .about-me {
    margin-top: 10px;
  }

  .tech-used {
    margin-top: 20px;
  }
  .onProj {
    position: inherit !important;
  }
  .project-links > a {
    color: #303030 !important;
    font-family: Aoboshi !important;
    font-size: 12px !important;
  }
}

.portrait-card {
  overflow: hidden;
  margin: auto;
  width: 300px;
  height: 400px;
  border-radius: 30px;
  box-shadow: 6px 6px 12px #969696, -6px -6px 12px #ffffff;
}

.my-picture {
  position: static;
}

.signature {
  margin-top: -10px;
  font-size: 38px;
  font-family: "Homemade Apple", cursive !important;
  transform: translateY(100px);
  color: #303030;
  animation: signing 1s linear;
  text-shadow: -5px 7px 2px rgb(197, 197, 197),
    1px 2px 1px rgba(255, 255, 255, 0.486) !important;
  font-style: italic;
}

@keyframes signing {
  0% {
    opacity: 0.4;
  }
  25% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.abt {
  display: block;
}

@media only screen and (min-width: 900px) {
  .portrait {
    order: 1;
    margin-right: 2rem;
  }

  .about-me {
    order: 2;
    width: 50%;
  }
  #footer-icon {
    margin-right: 0em !important;
    margin-right: none !important;
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1000px) {
  .my-section {
    text-align: center !important;
  }

  .cont-space {
    margin: 20px 0;
  }

  .my-intro {
    padding-top: 15px;
    text-align: center;
    margin: auto;
    max-width: 400px;
  }

  .skill-slider {
    margin: 20px 0;
  }

  #skill-icon {
    font-size: 28px !important;
    margin: auto;
    max-width: 400px;
    text-align: center;
  }
}

.about-me {
  color: #303030;
  float: right;
  padding: 10px;
  margin-bottom: 5px;
}

.about-me p {
  font-size: 14px;
}

.my-intro {
  color: #303030;
  font-family: Aoboshi !important;
}

.h {
  border-bottom: 0.5px solid #303030;
  color: #303030;
  font-family: Aoboshi !important;
}

.skills {
  padding-top: 70px;
}

.slide {
  padding-top: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .icons {
    display: grid !important;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 20px;
    text-align: center !important;
  }
}

.icons {
  display: flex;
  justify-content: center;
}

.onProj {
  justify-content: inherit !important;
  position: absolute;
  left: 1em;
  margin-top: 1em;
}

.leftAl {
  margin-top: 4em;
}

#skill-icon {
  font-size: 3rem;
  color: rgb(240, 240, 240);
  text-shadow: -1px 1px 4px #838383;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.resume {
  display: block;
  color: white;
}

.head {
  display: flex;
}

.location-address {
  float: right;
}

.initials {
  margin: auto;
  text-align: center;
}

.personal-details {
  margin: 10px;
  float: right;
}

@media screen and (max-width: 700px) {
  .job-title {
    font-size: 16px !important;
  }
  .job-desc {
    font-size: 12px !important;
  }
}

.experience {
  color: #303030 !important;
  text-align: left;
  margin-left: 10px;
  padding-bottom: 15px;
  border-bottom: 1.5px solid grey;
}

.personal-item {
  list-style: none;
  display: list-item;
}

#professional {
  border-bottom: 1.5px solid grey;
}

.work-experience {
  padding-top: 15px;
}

.company {
  font-size: 18px;
}

.job-title {
  font-size: 28px;
}

.job-desc {
  width: 60%;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .all-projects {
    padding-bottom: 5% !important;
  }
  .single-project {
    max-width: 300px !important;
    max-height: 170px !important;
    margin: auto !important;
    margin-bottom: 5em !important;
  }
}

.projects {
  display: flex;
  margin-top: 5em;
  justify-content: center;
}

.single-project {
  max-width: 400px;
  max-height: 250px;
  margin: 1.2em;
}

.project-image {
  border-radius: 15px;
  box-shadow: 6px 6px 12px #a3a3a3, -6px -6px 12px #ffffff;
  opacity: 0.9;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.single-project:hover .project-image {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.55s ease-in-out;
  -moz-transition: opacity 0.55s ease-in-out;
  -webkit-transition: opacity 0.55s ease-in-out;
  box-shadow: 6px 6px 12px #838383, -6px -6px 12px #ffffff;
}

.white {
  color: white !important;
}

.icons i {
  font-size: 3rem;
  color: rgb(240, 240, 240);
  text-shadow: -1px 1px 4px #838383;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.single-project:hover .icons i {
  color: #303030;
  cursor: pointer;
}

.tech-used {
  display: flex;
  justify-content: center;
  text-align: center;
  width: auto;
}

.back {
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .back-home {
    margin-bottom: 20% !important;
  }
}

.btm {
  padding-bottom: 1.5em;
}

.back-home {
  width: 11em;
  height: 3.5em;
  font-family: "Cormorant", serif !important;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  margin-top: 3em;
}

.computer-display {
  position: absolute;
  right: 5%;
  top: 25%;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
}

.about-proj {
  text-align: left;
  width: 60%;
  margin-top: 1.5em;
  margin-left: 1.2em;
}

.about-proj h2 {
  font-family: Aoboshi !important;
  color: #303030;
}

.about-proj p {
  font-family: Aoboshi !important;
  font-size: 14px;
  color: #303030;
}

@media screen and (max-width: 900px) {
  .computer-display {
    margin: auto;
    display: block;
    float: none !important;
    padding-left: 0px;
  }

  .indv-project {
    padding: 10px;
  }
  .deployed-footer {
    background-color: transparent;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    bottom: 0 !important;
  }
}

.details {
  margin-top: -25px !important;
}

.deployed {
  color: white !important;
  margin: auto;
  display: block !important;
  text-align: center !important;
}

.no-project {
  margin-top: 20%;
  color: white;
}

.no-project h1 {
  font-weight: bold;
  text-transform: uppercase;
}
